@font-face {
  font-family: 'Circular';
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/lineto-circular-book.woff2') format('woff2'),
    url('./fonts/lineto-circular-book.woff') format('woff');
}
@font-face {
  font-family: 'Circular';
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/lineto-circular-medium.woff2') format('woff2'),
    url('./fonts/lineto-circular-medium.woff') format('woff');
}
@font-face {
  font-family: 'Circular';
  font-weight: bold;
  font-display: swap;
  src: url('./fonts/lineto-circular-bold.woff2') format('woff2'),
    url('./fonts/lineto-circular-bold.woff') format('woff');
}
